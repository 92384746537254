<template>
	<div id="products">
		<h2 class="text-titulo section-title">{{ $store.state.titulofix }}</h2>
		<v-spacer class="my-3"></v-spacer>
		<v-container fluid>
			<v-row dense>
				<v-col cols="12" md="auto">
					<v-btn  
						@click="fnBrandSel"
						color="grey darken-3"
						class="my-2"
						large
						dark>
						<v-icon left>mdi-sync</v-icon>Reload
					</v-btn>
				</v-col>
				<v-col cols="12" md="auto">
					<v-select 
						:items="brands" 
						v-model="brand"
						label="Brands"
						style="width:200px"
						@change="fnBrandSel">
						<template v-slot:item="{ item }">
							{{ item.text.toUpperCase() }}
						</template>
					</v-select>
				</v-col>
				<v-col cols="12" md="auto">
					<v-text-field 
						v-model="buscador" 
						label="Search"
						v-on:keyup.enter="fnSearch"
						style="width:200px"
						:clearable="true"
						:disabled="brand==null">
					</v-text-field>
				</v-col>
				<v-spacer></v-spacer>
				<v-col cols="12" md="1">
					<v-text-field
						v-model="porcentaje"
						label="Sale %"
						color="purple"
						@keyup.native="fnChkInput(1)"></v-text-field>
				</v-col>
				<v-col cols="12" md="1">
					<v-text-field
						v-model="compAt"
						label="Base $"
						color="pink"
						@keyup.native="fnChkInput(1)"></v-text-field>
				</v-col>
				<v-col cols="12" md="1">
					<v-text-field
						v-model="price"
						label="Final $"
						color="indigo"
						@keyup.native="fnChkInput(2)"></v-text-field>
				</v-col>
				<v-col cols="12" md="1">
					<v-text-field
						v-model="tagin"
						label="Tag in"
						color="blue-grey darken-1"
						@keyup.native="fnChkInput(3)"></v-text-field>
				</v-col>
				<v-col cols="12" md="1">
					<v-text-field
						v-model="tagout"
						label="Tag out"
						color="blue-grey darken-2"
						@keyup.native="fnChkInput(3)"></v-text-field>
				</v-col>
				<v-col cols="12" md="1">
					<v-btn 
						large 
						color="grey darken-3 white--text" 
						:loading="stUpdLoad" 
						@click="fnUpdate">
							<v-icon left>mdi-upload</v-icon>Update
					</v-btn>
				</v-col>
			</v-row>
		</v-container>
		<!-- tabla -->
		<v-data-table
			:headers="cabecera"
			:items="listado"
			:loading="stLoading"
			:items-per-page="limit"
			:expanded.sync="abiertos"
			v-model="selected"
			:item-class="fnRowColor"
			disable-sort
			show-select
			show-expand
			single-expand
			hide-default-footer
			@item-expanded="fnDetalle">

			<template v-slot:item.product="{ item }">
				<v-edit-dialog 
					:return-value.sync="item.product" 
					large 
					persistent
					@save="fnSaveName(item.id, item.product)">
					<div>{{ item.product }}</div>
					<template v-slot:input>
						<v-text-field
						v-model="item.product"
						label="Edit"
						single-line
						counter
						autofocus
						style="width: 450px;"
						></v-text-field>
					</template>
				</v-edit-dialog>
			</template>

			<template v-slot:item.precios="{ item }">
				<v-chip-group>
					<v-chip class="mx-1 precios" small label color="red">${{ item.compAt }}</v-chip>
					<v-chip class="mx-1 precios" small label color="green">${{ item.price }}</v-chip>
					<v-chip class="mx-1 precios" small label color="orange">{{ fnSale(item.compAt, item.price) }}%</v-chip>
				</v-chip-group>
			</template>

			<template v-slot:item.tools="{ item }">
				<!--<v-btn @click="fnEdit(item.id, item.product)" :loading="btnloadid === item.id" color="green lighten-1" icon><v-icon>mdi-tooltip-edit</v-icon></v-btn>-->
				<v-btn @click="fnDelete(item.id)" color="red lighten-1" icon fab><v-icon>mdi-eye-off</v-icon></v-btn>
			</template>

			<template v-slot:expanded-item="{ headers, item }">
				<td :colspan="headers.length" style="padding:0;">
					<v-card class="pa-4 grey darken-2" flat>
						<v-sheet
							color="grey lighten-4"
							class="px-3 pt-3 pb-3"
							v-if="preload"
							>
							<v-skeleton-loader
							class="mx-auto"
							max-width="100%"
							type="table-row-divider"
							></v-skeleton-loader>
						</v-sheet>
						<v-simple-table dense v-else>
							<template v-slot:default>
								<thead class="grey lighten-1">
									<tr>
										<th>ID</th>
										<th>SKU</th>
										<th style="text-align: center">Variant</th>
										<th style="text-align: center">Stock</th>
										<th>Weight</th>
										<th>Base</th>
										<th>Price</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="row in item.details" :key="row.id">
										<td>{{ row.id }}</td>
										<td>{{ row.sku }}</td>
										<td style="text-align: center">{{ row.variant }}</td>
										<td style="text-align: center">{{ row.stock }}</td>
										<td>{{ row.weight }}oz.</td>
										<td>${{ row.compAt }}</td>
										<td>${{ row.price }}</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-card>
				</td>
			</template>

			<template v-slot:footer>
				<v-row class="pa-2">
					<v-col>
						<v-select :items="paginacion" @change="fnLoad()" v-model="limit" label="Items" style="max-width:100px;"></v-select>
					</v-col>
					<v-spacer></v-spacer>
					<v-col md="auto">
						<v-btn :disabled="stPrev" @click="fnChange('prev')" outlined color="dark" large class="mx-2"><v-icon left>mdi-chevron-left</v-icon>Prev</v-btn>
						<v-btn :disabled="stNext" @click="fnChange('next')" outlined color="dark" large>Next<v-icon right>mdi-chevron-right</v-icon></v-btn>
					</v-col>
				</v-row>
			</template>
		</v-data-table>
		<!-- Popup -->
		<v-dialog v-model="wnForm" persistent max-width="800px">
			<v-card>
				<v-card-title>
					<span class="text-titulo">{{ wnTitulo }}</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row v-for="linea in wnFormData" :key="linea.sku">
							<v-col cols="12" md="3">
								<v-text-field
									v-model="linea.sku"
									label="SKU"
									disabled
									color="primary"></v-text-field>
							</v-col>
							<v-col cols="12" md="3">
								<v-text-field
									v-model="linea.title"
									label="Variant"
									disabled
									color="primary"></v-text-field>
							</v-col>
							<v-col cols="12" md="2">
								<v-text-field
									v-model="linea.compAt"
									label="Base price"
									prepend-icon="mdi-cash-usd"
									color="red"></v-text-field>
							</v-col>
							<v-col cols="12" md="2">
								<v-text-field
									v-model="linea.price"
									label="Final price"
									prepend-icon="mdi-cash-usd"
									color="green"></v-text-field>
							</v-col>
							<v-col cols="12" md="2">
								<v-text-field
									v-model="linea.stock"
									label="Stock"
									color="primary"></v-text-field>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="wnForm = false"><v-icon left>mdi-close-circle</v-icon>Close</v-btn>
					<v-btn text @click="wnForm = false"><v-icon left>mdi-content-save</v-icon>Save</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import axios from "axios";

export default {
	name: 'musproducts',
	metaInfo: {
		title: 'MUS Products'
	},
	data() {
		return {
			cabecera: [
				{ text: 'Name', value: 'product' },
				{ text: 'Price', value: 'precios' },
				{ text: 'Stock', value: 'stock' },
				{ text: 'Vendor', value: 'vendor' },
				{ text: 'Type', value: 'type' },
				{ text: 'Tags', value: 'tags', cellClass: 'tagsmax' },
				{ text: 'Status', value: 'status', cellClass: 'text-no-wrap' },
				{ text: 'Action', value: 'tools', width: '150px', align: 'center' }
			],
			listado: [],
			paginacion: [10, 30, 45, 60],
			brands: [],
			abiertos: [],
			selected: [],
			stLoading: false,
			buscador: null,
			porcentaje: null,
			compAt: null,
			price: null,
			tagin: null,
			tagout: null,
			brand: null,
			limit: 30,
			cursor: null,
			dir: 'next',
			accion: null,
			preload: false,
			snack: false,
			snackColor: 'success',
			snackText: null,
			stPrev: true,
			stNext: true,
			stUpdLoad: false,
			wnForm: false,
			wnFormData: [],
			btnloadid: null,
			wnTitulo: null
		}
	},
	mounted() {
		axios.post('/mus-brands')
			.then(response => this.brands = response.data)
		this.$store.state.titulofix = 'Products from MUS'
	},
	methods: {
		fnLoad() {
			this.stLoading = true
			this.abiertos = []
			axios.post('/mus-products', { marca: this.brand, limit: this.limit, cursor: this.cursor, dir: this.dir, word: this.buscador })
				.catch(error => console.log(error.response))
				.then(response => {
					if(response.data) {
						this.stPrev = !response.data.previous
						this.stNext = !response.data.next
						this.listado = response.data.list.map((item) => {
								return {
									details: {},
									...item
								}
						})
					}else{
						this.$store.state.mensaje = 'Error: cannot list the products. Wait a moment and try again.'
						this.$store.state.estado = 'error'
						this.$store.state.snackme = true
						this.$errorsnd()
					}
					this.stLoading = false
					this.stUpdLoad = false
				})
		},
		fnSearch() {
			this.cursor = null
			this.fnLoad()
		},
		fnSale(compAt, price) {
			return Math.round((compAt - price) / compAt * 100);
		},
		fnRowColor(row) {
			let color = ''
			switch(row.sale) {
				case 'Clearance':
					color = 'blue-grey lighten-4'
					break
				case null:
					color = 'red lighten-4'
					break
				default:
					color = ''
			}
			return color
		},
		fnBrandSel() {
			this.buscador = null
			this.cursor = null
			this.porcentaje = null
			this.compAt = null
			this.price = null
			this.tagin = null
			this.tagout = null
			this.selected = []
			this.abiertos = []
			this.fnLoad()
		},
		fnEdit(id, nombre) { 
			this.btnloadid = id
			axios.post('/mus-variant', { id: id })
				.then(response => {
					this.wnFormData = response.data
					this.wnTitulo = nombre
					this.wnForm = true
					this.btnloadid = false
				})
		},
		fnDelete(id) { 
			axios.post('/mus-disable', { id: id })
				.then(response => {
					if(response.data) {
						this.$store.state.mensaje = 'The product was disabled successfully.'
						this.$store.state.estado = 'success'
						this.$store.state.snackme = true
						this.$successsnd()
						this.fnLoad()
					}else{
						this.$store.state.mensaje = 'Error: cannot unpublish product. Wait a moment and try again.'
						this.$store.state.estado = 'error'
						this.$store.state.snackme = true
						this.$errorsnd()
						this.fnLoad()
					}
				})
			
		},
		fnDetalle({item, value}) {
			if(value) {
				this.preload = true
				axios.post('/mus-subprod', { id: item.id })
				.catch(error => console.log(error))
				.then(response => { 
					item.details = response.data 
					this.preload = false
				})
			}
		},
		fnSaveName(id, name) {
			axios.post('/mus-savename', { id: id, text: name })
				.then(response => {
					if(response.data) {
						this.fnLoad()
						this.$store.state.mensaje = 'The product name was update.'
						this.$store.state.estado = 'success'
						this.$store.state.snackme = true
						this.$successsnd()
					}else{
						this.$store.state.mensaje = 'Error: something go wrong with the server please try again.'
						this.$store.state.estado = 'error'
						this.$store.state.snackme = true
						this.$errorsnd()
					}
				})
		},
		fnUpdate() {
			if(this.selected.length == 0) {
				this.$store.state.mensaje = 'Select at least one row to process.'
				this.$store.state.estado = 'warning'
				this.$store.state.snackme = true
				this.$warningsnd()
				return
			}
			if(!this.porcentaje & !this.compAt & !this.price & !this.tagin & !this.tagout ) {
				this.$store.state.mensaje = 'Do not forget to put a value to calculate.'
				this.$store.state.estado = 'warning'
				this.$store.state.snackme = true
				this.$warningsnd()
				return
			}
			let ids = []
			this.stUpdLoad = true
			this.selected.forEach(resultado => {
				ids.push(resultado.id)
			})
			axios.post('/mus-massupdate', { 
				ids: ids, 
				percentaje: this.porcentaje, 
				compAt: this.compAt, 
				price: this.price, 
				tagin: this.tagin, 
				tagout: this.tagout 
			})
			.then(response => {
				if(response.data) { 
					this.selected = []
					this.$store.state.mensaje = 'The current batch of products were update.'
					this.$store.state.estado = 'success'
					this.$store.state.snackme = true
					this.$successsnd()
					this.fnLoad()
				}else{
					this.$store.state.mensaje = 'Something go wrong, please try later.'
					this.$store.state.estado = 'error'
					this.$store.state.snackme = true
					this.$errorsnd()
				}
				this.stUpdLoad = false
			})
		},
		fnChange(dir) {
				if(dir == 'next') {
					const len = Object.keys(this.listado).length;
					this.cursor = this.listado[len-1].cursor;
				}else{
					this.cursor = this.listado[0].cursor;
				}
				this.dir = dir;
				this.selected = []
				this.abiertos = []
				this.listado = []
				this.fnLoad();
		},
		fnChkInput(option) {
			switch(option) {
				case 1:
					this.price = null
					this.tagin = null
					this.tagout = null
					break
				case 2:
					this.porcentaje = null
					this.tagin = null
					this.tagout = null
					break
				case 3:
					this.porcentaje = null
					this.compAt = null
					this.price = null
					break
			}
		}
	}
}	
</script>

<style scope>
.col-middle {
	vertical-align: middle;
}
.v-chip.precios {
	font-weight: bold;
	color: white;
	font-family: monospace;
}
.tagsmax {
	max-width: 500px;
}
</style>